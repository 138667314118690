import { Route, Routes } from "react-router";
import React, { useState } from "react";
import Loading from './Components/JS/Loading';
const  Contact =  React.lazy(() => import('./Components/JS/Contact'));
const  Home =  React.lazy(() => import('./Components/JS/Home'));
const  Services =  React.lazy(() => import('./Components/JS/Services'));
const  Service =  React.lazy(() => import('./Components/JS/Service'));
const  Affiliates =  React.lazy(() => import('./Components/JS/Affiliates'));
const  NotFound =  React.lazy(() => import('./Components/JS/NotFound'));

function App() {

  const [navbarStatus, setnavbarStatus] = useState({color:"white", activelink:"home"})

  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<React.Suspense fallback={<Loading/>}> <Home navbarStatus={navbarStatus} setnavbarStatus={setnavbarStatus}/> </ React.Suspense>}/>
        <Route path="/Contact" element={<React.Suspense fallback={<Loading/>}> <Contact navbarStatus={navbarStatus} setnavbarStatus={setnavbarStatus}/> </ React.Suspense>} />
        <Route path="/Affiliates" element={<React.Suspense fallback={<Loading/>}> <Affiliates navbarStatus={navbarStatus} setnavbarStatus={setnavbarStatus}/> </ React.Suspense>} />
        <Route path="/Services" element={<React.Suspense fallback={<Loading/>}> <Services navbarStatus={navbarStatus} setnavbarStatus={setnavbarStatus}/> </ React.Suspense>} />
        <Route path="/Webdev" element={<React.Suspense fallback={<Loading/>}> <Service service={'Webdev'} navbarStatus={navbarStatus} setnavbarStatus={setnavbarStatus}/> </ React.Suspense>} />
        <Route path="/Mobiledev" element={<React.Suspense fallback={<Loading/>}> <Service service={'Mobiledev'} navbarStatus={navbarStatus} setnavbarStatus={setnavbarStatus}/> </ React.Suspense>} />
        <Route path="/Socialmedia" element={<React.Suspense fallback={<Loading/>}> <Service service={'Socialmedia'} navbarStatus={navbarStatus} setnavbarStatus={setnavbarStatus}/> </ React.Suspense>} />
        <Route path="*" element={<React.Suspense fallback={<Loading/>}> <NotFound navbarStatus={navbarStatus} setnavbarStatus={setnavbarStatus}/> </ React.Suspense>} />
      </Routes>
    </div>
  );
}

export default App;
