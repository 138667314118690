import '../CSS/Loading.css'
import logo from '../../Images/W-logo.svg';

export default function Loading() {
  return (
    <div className='loading-div'>
      <img src={logo} alt="redspot white logo" />
    </div>
  )
}
