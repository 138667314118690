import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          navbar:{
            home: 'Home',
            services: 'Services',
            contact: 'Contact',
            affiliates: 'For affiliates',
            bookdemo: 'Book a demo'
          },
          notfound:{
            h1: "You've found the page that doesn't exist",
            p: "Maybe it's you, maybe it's us. Breath in, breath out, and try again",
            link: 'Go back'
          },
          home: {
            title: "Welcome to redspot",

            top_h1: 'We are digital marketing ',
            top_h1_span: 'Boosters.',
            top_p_1: 'We are a digital agency helping businesses and individuals establish a strong digital presence.',
            top_p_2: 'We specialize in product development.',
            top_cta: 'Lets talk',

            services_h2: 'Our services.',
            services_p: 'Checkout how redspot can help you take your business to new highs',
            services_webdev_h3: 'Web development.',
            services_webdev_p: 'We create custom websites for all businesses, from e-commerce to portfolios & everything in between',
            services_mobiledev_h3: 'Mobile development.',
            services_mobiledev_p: 'Android, IOS or hybrid, you ideate and we create',
            services_payedads_h3: 'Payed ads.',
            services_payedads_p: 'Drive targeted traffic and achieve your marketing goals for maximum ROI',
            services_socialmedia_h3: 'Social media content creation.',
            services_socialmedia_p: 'Build your brand and connect with your audience by taking your Social Media content to a next level',
            services_cta: 'Find out more',

            text_h3_1: 'INNOVATIVE MARKETING',
            text_h3_3: 'For ',
            text_h3_3_span: 'MODERN WORLD.',
            text_p: "At redspot, we're passionately dedicated to providing innovative marketing solutions that keep your business ahead of the curve in an ever-changing digital landscape.",
            
            values_h2: 'Our values.',
            values_p: 'We are dedicated to embodying these values in every interaction we have, both with our clients and with each other.',
            values_1: 'Striving for excellence',
            values_2: 'Reliability',
            values_3: 'Respect and friendship',
            values_4: 'Integrity',

            faq_h2: 'FAQs',
            faq_q1:'How much do you charge for a website?',
            faq_a1:'Prices differ depending on the complexity of the project, technologies to be used, the design, company size,etc. They range from 500 to 10.000 american dollars',
            faq_q2:'Do you have an affiliates program',
            faq_a2:'Yes, you can find all the details about it on the "For affiliates" page on the navbar',
            faq_q3:'Can you do the development part of my website if I already have the UI?',
            faq_a3:'Yes we can',
            faq_q4:'How do you measure the success of your marketing campaigns?',
            faq_a4:"This depends on the client's aspirations, their business goals and the campaigns goals, these measures of success will be defined with the client in the first couple of meetings",
            faq_q5:'Where is your office located?',
            faq_a5:"We know you want to see us! But we believe it is a waste of ressources, effort and time to invest in a physical office as long as all our work can be done online where we can be more reachable to our clients anywhere, anytime."

          },
          service: {
            title_webdev: "Web development services",
            title_mobiledev: "Mobile development services",
            title_socialmedia: "Social media services",

            top_h1_webdev: 'design & development.',
            top_span_webdev: 'Web',
            top_p_webdev: "Elevate your brand's online presence with our expert web design and development services. And no, we can't make your website sing and dance, but we can make it look darn good.",
            top_h1_mobiledev: 'design & development.',
            top_span_mobiledev: 'Mobile',
            top_p_mobiledev: "Experience seamless mobile experience with our expert design and development services. Let's bring your vision to life.",
            top_h1_socialmedia: 'content creation.',
            top_span_socialmedia: 'Social media',
            top_p_socialmedia: "We take social media seriously, so you don't have to. Let us handle the hard work, while you focus on your business",
            top_cta: 'Start a project',

            infos_h2_webdev: 'Technologies we use.',
            infos_h2_mobiledev: 'Technologies we use.',
            infos_h2_socialmedia: 'About this service.',
            infos_p1_socialmedia: "In today's digital age, social media has become a crucial component of any successful marketing strategy. However,  businesses are often struggling to ideate and create quality content on a regular basis.",
            infos_p2_socialmedia: "Our team of experts can take care of creating your social media marketing strategy, launching marketing campaigns and creating the content’s copies and visuals, and also take care of the positing for you, helping you cut time, so you can focus on other aspects of your business",

            process_h2: 'Our process.',
            process_p_webdev: "From discovery to launch, this is the approach we take in our development process, making sure you’re kept involved through transparent communication and regular check-ins.",
            process_p_mobiledev: "From discovery to launch, this is the approach we take in our development process, making sure you’re kept involved through transparent communication and regular check-ins.",
            process_p_socialmedia: "From discovery to launch, this is the approach we take in our content creation process, making sure you’re kept involved through transparent communication and regular check-ins.",
            process_webmobile_1: 'Expectations Setting',
            process_webmobile_2: 'UX Research',
            process_webmobile_3: 'UI Design',
            process_webmobile_4: 'Coding',
            process_webmobile_5: 'Hosting',
            process_webmobile_6: 'Delivery',
            process_socialmedia_1: 'Expectations Setting',
            process_socialmedia_2: 'Market research',
            process_socialmedia_3: 'Competitors Analysis',
            process_socialmedia_4: 'Campaign draft creation',
            process_socialmedia_5: 'Content Creation',
            process_socialmedia_6: 'Delivery and posting',

            provides_h2: 'Why work with us.',
            provides_webdev_h3_1: 'Hosting',
            provides_webdev_h3_2: 'SEO',
            provides_webdev_h3_3: 'Security',
            provides_webdev_h3_4: 'Guarantee',
            provides_webdev_p_1: 'Our team handles all the technical aspects of website hosting so you can focus on your business',
            provides_webdev_p_2: 'We build all of our websites with SEO in mind, optimizing content, metadata, and site architecture to improve search engine visibility and rankings',
            provides_webdev_p_3: 'We use industry-standard security protocols and best practices to protect your website from hacking, malware, and other security threats',
            provides_webdev_p_4: 'We offer a 1 year guarantee for all our website, covering code and hosting to ensure the website functions at optimal levels',
            provides_mobiledev_h3_1: 'Hosting',
            provides_mobiledev_h3_2: 'Security',
            provides_mobiledev_h3_3: 'Guarantee',
            provides_mobiledev_p_1: 'Our team handles all the technical aspects of website hosting so you can focus on your business',
            provides_mobiledev_p_2: 'We use industry-standard security protocols and best practices to protect your website from hacking, malware, and other security threats',
            provides_mobiledev_p_3: 'We offer a 1 year guarantee for all our website, covering code and hosting to ensure the website functions at optimal levels',
            provides_socialmedia_h3_1: 'Multi-channel distribution',
            provides_socialmedia_h3_2: 'Brand alignment',
            provides_socialmedia_h3_3: 'Data driven',
            provides_socialmedia_h3_4: 'Account management',
            provides_socialmedia_p_1: 'Our team has expertise in designing for social media platforms, ensuring that your content is optimized for each platform.',
            provides_socialmedia_p_2: "We create content that aligns with your brand to ensure consistency, if you’re brand is not well known. we’ll work on a brand awareness campaign.",
            provides_socialmedia_p_3: 'We continuously measure the performance of your content to refine and optimize it for maximum impact',
            provides_socialmedia_p_4: 'We offer an additional service of managing your SM accounts, from replying to messages and comments to taking  and tracking orders'
          },
          contact: {
            title: "Contact us",

            form_h1: 'You have a project? ',
            form_h1_span: 'Lets get to work',
            form_service_label: "I'm looking for",
            form_service_1: 'UI/UX Design',
            form_service_2: 'Web app',
            form_service_3: 'Mobile app',
            form_service_4: 'Content creation',
            form_service_5: 'Other',
            form_name_placeholder: 'Your name',
            form_name_message: 'your name is required',
            form_email_placeholder: 'Your email',
            form_email_message_1: 'your email is required',
            form_email_message_2: 'your email must be valid',
            form_details_placeholder: 'Tell us more about your project',
            form_details_message: 'details about the project are required',
            form_submit: 'Submit',
            bookdemo_p: 'or',
            bookdemo_a: 'Book a demo'
          },
          services: {
            title:'Our services',

            h1: 'Our ',
            h1_span: 'services',
            p:'From designing your web and mobile apps to creating content and managing your social media and everything in between, we got you covered!',
            webdev: 'Web design and development',
            mobiledev: 'Mobile design and development',
            socialmedia: 'Social media content creation',
            payedads: 'Payed ads (currently unavailable)',
            cta: 'Start a project'
          },
          affiliates: {
            title:"Become an affiliate",

            top_h1: 'by joining our affiliate program',
            top_h1_span: 'Become a partner',
            top_p1: 'Refer clients to redspot for one of our services and get a lifetime ',
            top_p2: 'on every service we offer them!',
            top_p_span: '10% commission ',
            top_join_btn: 'Join now',

            main_h2_1: 'How it works',
            main_p_1_1: 'Join our affiliate program and start earning commissions by referring clients to our agency. You help them get top-notch digital marketing services, and you get rewarded for it.',
            main_p_1_2: 'For each referral that woks with us, you get a commission of 10% on all the deals we make with them... FOREVER',
            main_h2_2: 'Who can be an affiliate',
            main_p_2: 'Anyone can join our program, whether you are sales specialist, a UI/UX designer who can refer clients for the development part of their apps, or even if you are just someone who has a wide network of people who can benefit from our services.',
            main_h2_3: 'What you will gain',
            main_p_3_1: 'Earn 10% commission on every deal we make with your referrals, in addition to getting access to our network of like-minded professionals and access to exclusive resources and support.',
            main_p_3_2: "If you are a UI/UX designer who refers client for the development of their apps, you'll get free promotion by getting your designs featured on our portfolio and social media accounts, and an opportunity to work as a contractor with us in the futur",

            joinflow_h2: 'How to join the program',
            joinflow_h3_1: 'Fill the form at the bottom',
            joinflow_h3_2: 'Do a virtual meeting with us',
            joinflow_h3_3: 'Start marketing',
            joinflow_h3_4: 'Receive your commissions',

            quote_h2: '“Refer a client once and get commissions for all the services they get forever, this is the best affiliate opportunity out there”',
            quote_h4: 'Rafik Kouider Hacene',
            quote_p: '-redspot founder-',

            form_h2_1: 'Ready to do it?',
            form_h2_2: 'Fill the form and join the team',
            form_firstname_placeholder: 'First name',
            form_lastname_placeholder: 'Last name',
            form_email_placeholder: 'Your email',
            form_firstname_message: 'your first name is required',
            form_lastname_message: 'your last name is required',
            form_email_message_1: 'your email is required',
            form_email_message_2: 'your email must be valid',

            question_p:"Still have questions? ",
            question_link: 'contact us'
          },
          bottomsection: {
            webdev_h2: "Don't settle for mediocrity",
            webdev_p: "Your website deserves better than your cousin's friend who knows a little bit about coding.",
            webdev_cta: 'Lets create a masterpiece',
            mobiledev_h2: 'Ready to do it?',
            mobiledev_p: "We can't guarantee our apps will solve all your problems, but they'll definitely distract you from them.",
            mobiledev_cta: 'Lets create a masterpiece',
            socialmedia_h2: "Ready to do it?",
            socialmedia_p: "We promise to be more entertaining than your aunt's Facebook posts",
            socialmedia_cta: 'Lets start creating',
            default_h2: "Don't settle for mediocrity",
            default_p: "Choose excellence and let's create something awesome together.",
            default_cta: "Lets create together"
          }
        }
      },
      fr: {
        translation: {
          navbar:{
            home: 'Accueil',
            services: 'Services',
            contact: 'Contact',
            affiliates: 'Pour les affiliés',
            bookdemo: 'Réserver une démo'
          },
          notfound:{
            h1: "Vous avez trouvé la page qui n'existe pas",
            p: "Peut-être que c'est vous, peut-être que c'est nous. Respirez profondément, expirez lentement et essayez à nouveau",
            link: 'Retourner'
          },
          home: {
            title: "Bienvenue chez redspot",

            top_h1: 'Nous sommes des boosters de ',
            top_h1_span: 'Marketing digital.',
            top_p_1: 'redspot est une agence digitale qui aide les entreprises et les particuliers à établir une forte présence digitale.',
            top_p_2: 'On est spécialisés dans le développement des sites web et applications mobile',
            top_cta: 'Contactez-nous',

            services_h2: 'Nos services.',
            services_p: 'Découvrez comment Redspot peut vous aider à faire décoller votre entreprise vers de nouveaux sommets.',
            services_webdev_h3: 'Développement web.',
            services_webdev_p: 'Nous créons des sites web personnalisés pour toutes le monde, du E-commerce aux portfolios et tout ce qui se trouve entre les deux',
            services_mobiledev_h3: 'Développement mobile.',
            services_mobiledev_p: 'Android, IOS ou hybride, vous imaginez et nous créons',
            services_payedads_h3: 'Publicités payantes.',
            services_payedads_p: 'Générez un trafic ciblé et atteignez vos objectifs marketing pour un retour sur investissement maximal',
            services_socialmedia_h3: 'Création de contenu.',
            services_socialmedia_p: 'Construisez votre brand et connectez-vous avec votre audience en prenant vos réseaux sociaux au niveau supérieur.',
            services_cta: 'Savoir plus',

            text_h3_1: 'MARKETING INNOVANT',
            text_h3_3: 'POUR LE ',
            text_h3_3_span: 'MONDE MODERNE.',
            text_p: "Chez Redspot, nous sommes passionnément dévoués à fournir des solutions de marketing innovantes qui permettent à votre business de se démarquer dans un monde numérique en constante évolution.",
            
            values_h2: 'Nos valeurs.',
            values_p: '"Nous sommes déterminés à incarner ces valeurs dans chaque interaction que nous avons, à la fois avec nos clients et entre nous.',
            values_1: "En quête d'excellence",
            values_2: 'Fiabilité',
            values_3: 'Respect et amitié',
            values_4: 'Intégrité',

            faq_h2: 'FAQs',
            faq_q1: 'Combien facturez-vous pour un site web?',
            faq_a1:"'Les prix varient en fonction de la complexité du projet, des technologies à utiliser, de la conception, de la taille de l'entreprise, etc.",
            faq_q2: "Avez-vous un programme d'affiliation?",
            faq_a2: 'Oui, vous pouvez trouver tous les détails à ce sujet sur la page "Pour les affiliés" dans la barre de navigation.',
            faq_q3: "Pouvez-vous réaliser la partie développement de mon site web si j'ai déjà le design?",
            faq_a3: 'Oui, nous le pouvons.',
            faq_q4: 'Comment mesurez-vous le succès de vos campagnes marketing?',
            faq_a4: "Cela dépend des aspirations du client, de ses objectifs commerciaux et des objectifs de la campagne. Ces mesures de succès seront définies avec le client lors des premières réunions.",
            faq_q5: 'Où se trouve votre bureau?',
            faq_a5: "Nous savons que vous voulez nous rencontrer ! Mais nous croyons que c'est une perte de ressources, d'efforts et de temps d'investir dans un bureau physique tant que tout notre travail peut être fait en ligne où nous pouvons être plus accessibles à nos clients n'importe où, n'importe quand."

          },
          service: {
            title_webdev: "Développement web",
            title_mobiledev: "Développement mobile",
            title_socialmedia: "Création de contenue",

            top_h1_webdev: 'Conception et développement.',
            top_span_webdev: 'Web',
            top_p_webdev: "Rehaussez votre présence en ligne avec nos services d'expertise en conception et développement web. Et non, nous ne pouvons pas faire chanter et danser votre site web, mais nous pouvons le rendre vraiment bien conçu.",
            top_h1_mobiledev: 'Conception et développement.',
            top_span_mobiledev: 'Mobile',
            top_p_mobiledev: "Profitez d'une expérience mobile fluide grâce à nos services d'expertise en conception et développement. Donnons vie à votre vision.",
            top_h1_socialmedia: 'pour les réseaux sociaux.',
            top_span_socialmedia: 'Création de contenu',
            top_p_socialmedia: "We take social media seriously, so you don't have to. Let us handle the hard work, while you focus on your business",
            top_cta: 'Lancer un projet',

            infos_h2_webdev: 'Les technologies que nous utilisons.',
            infos_h2_mobiledev: 'Les technologies que nous utilisons.',
            infos_h2_socialmedia: 'À propos de ce service.',
            infos_p1_socialmedia: "Aujourd'hui, à l'ère du numérique, les réseaux sociaux sont devenus un élément crucial de toute stratégie marketing réussie. Cependant, les entreprises ont souvent du mal à concevoir et à créer du contenu de qualité sur une base régulière.",
            infos_p2_socialmedia: "Notre équipe d'experts peut s'occuper de la création de votre stratégie de marketing sur les réseaux sociaux, lancer des campagnes marketing, créer les copies et visuels de contenu, et également prendre en charge la publication pour vous, vous aidant ainsi à gagner du temps pour vous concentrer sur d'autres aspects de votre business.",

            process_h2: 'Notre processus.',
            process_p_webdev: "De la découverte au lancement, voici l'approche que nous adoptons dans notre processus de développement, en veillant à vous tenir informé grâce à une communication transparente et des points de contrôle réguliers.",
            process_p_mobiledev: "De la découverte au lancement, voici l'approche que nous adoptons dans notre processus de développement, en veillant à vous tenir informé grâce à une communication transparente et des points de contrôle réguliers.",
            process_p_socialmedia: "De la découverte à la publication, voici l'approche que nous adoptons dans notre processus de création de contenu, en veillant à ce que vous soyez impliqué tout au long du processus grâce à une communication transparente et des contrôles réguliers",
            process_webmobile_1: 'Définition des attentes',
            process_webmobile_2: 'Recherche UX',
            process_webmobile_3: 'Conception UI',
            process_webmobile_4: 'Codage',
            process_webmobile_5: 'Hébergement',
            process_webmobile_6: 'Livraison',
            process_socialmedia_1: 'Définition des attentes',
            process_socialmedia_2: 'recherche de marché',
            process_socialmedia_3: 'Competitors Analysis',
            process_socialmedia_4: "création d'ébauches de campagne",
            process_socialmedia_5: 'création de contenu',
            process_socialmedia_6: 'livraison et publication',

            provides_h2: 'Pourquoi travailler avec nous.',
            provides_webdev_h3_1: 'Hébergement',
            provides_webdev_h3_2: 'SEO',
            provides_webdev_h3_3: 'Sécurité',
            provides_webdev_h3_4: 'Garantie',
            provides_webdev_p_1: "Notre équipe gère tous les aspects techniques de l'hébergement de site Web pour que vous puissiez vous concentrer sur autre chose.",
            provides_webdev_p_2: "Nous concevons tous nos sites web en gardant à l'esprit le référencement naturel (SEO), en optimisant le contenu, les métadonnées et l'architecture du site pour améliorer la visibilité et le classement dans les moteurs de recherche",
            provides_webdev_p_3: 'Nous utilisons des protocoles de sécurité de norme industrielle et les meilleures pratiques pour protéger votre site web contre le piratage, les logiciels malveillants et autres menaces de sécurité',
            provides_webdev_p_4: "Nous offrons une garantie d'un an pour tous nos sites web, couvrant le code et l'hébergement pour assurer le bon fonctionnement optimal du site.",
            provides_mobiledev_h3_1: 'Hébergement',
            provides_mobiledev_h3_2: 'Sécurité',
            provides_mobiledev_h3_3: 'Garantie',
            provides_mobiledev_p_1: "Notre équipe gère tous les aspects techniques de l'hébergement de site Web pour que vous puissiez vous concentrer sur autre chose.",
            provides_mobiledev_p_2: 'Nous utilisons des protocoles de sécurité de norme industrielle et les meilleures pratiques pour protéger votre application mobile contre le piratage, les logiciels malveillants et autres menaces de sécurité',
            provides_mobiledev_p_3: "Nous offrons une garantie de'un an pour tous nos applications mobile, couvrant le code et l'hébergement pour assurer le bon fonctionnement optimal du site.",
            provides_socialmedia_h3_1: 'Distribution multicanal',
            provides_socialmedia_h3_2: 'Alignement de la marque',
            provides_socialmedia_h3_3: 'Axé sur les données',
            provides_socialmedia_h3_4: 'Gestion de compte',
            provides_socialmedia_p_1: 'Notre équipe possède une expertise dans la conception pour les plateformes de réseaux sociaux, garantissant que votre contenu est optimisé pour chaque plateforme..',
            provides_socialmedia_p_2: "Nous créons du contenu qui s'aligne avec votre marque pour assurer la cohérence. Si votre marque n'est pas très connue, nous travaillerons sur une campagne de sensibilisation de marque.",
            provides_socialmedia_p_3: "Nous mesurons continuellement les performances de votre contenu pour l'affiner et l'optimiser pour un impact maximal.",
            provides_socialmedia_p_4: 'Nous offrons un service supplémentaire de gestion de comptes, de la réponse aux messages et commentaires à la prise et au suivi des commandes.'
          },
          contact: {
            title: "Contactez nous",

            form_h1: 'Vous avez un projet? ',
            form_h1_span: 'Mettons-nous au travail',
            form_service_label: "Je suis à la recherche de",
            form_service_1: 'Conception UI/UX',
            form_service_2: 'Site web',
            form_service_3: 'Application mobile',
            form_service_4: 'Création de contenu',
            form_service_5: 'Autre',
            form_name_placeholder: 'Votre nom',
            form_name_message: 'Votre nom est requis',
            form_email_placeholder: 'Votre email',
            form_email_message_1: 'Votre email est requis',
            form_email_message_2: 'Votre adresse e-mail doit être valide',
            form_details_placeholder: 'Parlez-nous plus de votre projet',
            form_details_message: 'Les détails sur le projet sont requis',
            form_submit: 'Envoyer',
            bookdemo_p: 'ou',
            bookdemo_a: 'Réserver une démo'
          },
          services: {
            title:'Nos services',

            h1: 'Nos ',
            h1_span: 'services',
            p:"De la conception de vos applications web et mobiles à la création de contenu et à la gestion de vos réseaux sociaux, et tout ce qu'il y a entre les deux, nous avons tout ce qu'il vous faut !",
            webdev: 'Conception et développement web',
            mobiledev: 'Conception et développement mobile',
            socialmedia: 'Création de contenu pour les réseaux sociaux',
            payedads: 'Publicités payantes (actuellement indisponible)',
            cta: 'Démarrer un projet'
          },
          affiliates: {
            title:"Devenir un affilié",

            top_h1: "en rejoignant notre programme d'affiliation.",
            top_h1_span: 'Devenez partenaire',
            top_p1: "Référez des clients à Redspot pour l'un de nos services et obtenez une ",
            top_p2: 'sur chaque service que nous leur offrons!',
            top_p_span: 'commission à vie de 10 % ',
            top_join_btn: 'Rejoignez maintenant',

            main_h2_1: 'Comment ça fonctionne',
            main_p_1_1: 'Rejoignez notre programme d’affiliation et commencez à recevoir des commissions en recommandant notre agence au b clients. Vous les aidez à obtenir des services de marketing numérique de qualité supérieure et vous recevez vos commissions pour cela.',
            main_p_1_2: 'Pour tous les clients que vous ramenez, vous allez recevoir une commission de 10% sur toutes les transactions que nous effectuons avec eux... POUR TOUJOURS',
            main_h2_2: 'Qui peut être affilié',
            main_p_2: 'Tout le monde peut rejoindre notre programme, que vous soyez un spécialiste des ventes, un designer UI/UX qui peut recommander des clients pour la partie développement de leurs applications, ou même si vous avez simplement un large réseau de personnes qui peuvent bénéficier de nos services.',
            main_h2_3: 'Ce que vous allez gagner',
            main_p_3_1: 'Gagnez une commission de 10% sur chaque transaction que nous effectuons avec les clients que vous ramenez, en plus d’avoir accès à notre réseau de professionnels qui partageant les mêmes visions que vous et à des ressources et un support exclusifs.',
            main_p_3_2: "Si vous êtes un designer UI/UX qui recommande des clients pour le développement de leurs applications, vous bénéficierez d'une promotion gratuite en faisant figurer vos designs sur notre portfolio et nos reseaux sociaux, ainsi que d'une opportunité de travailler avec nous en tant que sous-traitant à l'avenir.",

            joinflow_h2: 'Comment rejoindre le programme',
            joinflow_h3_1: 'Remplir le formulaire ci-dessous',
            joinflow_h3_2: 'Faire une réunion virtuelle avec nous',
            joinflow_h3_3: 'Commencez à commercialiser',
            joinflow_h3_4: 'Recevez vos commissions',

            quote_h2: '“Référez un client une fois et obtenez des commissions pour tous les services qu’ils reçoivent pour toujours, c’est la meilleure opportunité d’affiliation qui existe”',
            quote_h4: 'Rafik Kouider Hacene',
            quote_p: '-Fondateur de Redspot-',

            form_h2_1: 'Prêt à le faire?',
            form_h2_2: 'Remplissez le formulaire et rejoignez l’équipe',
            form_firstname_placeholder: 'Prénom',
            form_lastname_placeholder: 'Nom de famille',
            form_email_placeholder: 'Votre e-mail',
            form_firstname_message: 'votre prénom est requis',
            form_lastname_message: 'votre nom de famille est requis',
            form_email_message_1: 'votre e-mail est requis',
            form_email_message_2: 'votre e-mail doit être valide',

            question_p:"Vous avez encore des questions? ",
            question_link: 'contactez-nous'
          },
          bottomsection: {
            webdev_h2: "Ne vous contentez pas de la médiocrité",
            webdev_p: "Votre site Web mérite mieux que l'ami de votre cousin qui connaît un peu de codage.",
            webdev_cta: "Créons un chef-d'œuvre",
            mobiledev_h2: 'Prêt à le faire?',
            mobiledev_p: "Nous ne pouvons pas garantir que nos applications résoudront tous vos problèmes, mais elles vous distrairont certainement de ces derniers.",
            mobiledev_cta: "Créons un chef-d'œuvre",
            socialmedia_h2: "Prêt à le faire ?",
            socialmedia_p: "Nous promettons d'être plus divertissants que les publications Facebook de votre tante",
            socialmedia_cta: 'Commençons à créer',
            default_h2: "Ne vous contentez pas de la médiocrité",
            default_p: "Choisissez l'excellence et créons quelque chose d'incroyable ensemble.",
            default_cta: "Nous contacter"
          }
        }
      }
    }
  });

export default i18n;